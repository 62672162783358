import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';
import Cookies from "universal-cookie";
const cookies = new Cookies();
          function HeaderFitur(){
            const [isOpen, setIsOpen] = useState(false);

            const toggle = () => setIsOpen(!isOpen);
            var user = JSON.parse(localStorage.getItem("data"))
            const logOut=()=>{
              localStorage.removeItem("data")
              if(window.location.hostname == 'localhost'){
                cookies.remove('dataUser');
                cookies.remove('idUser');
                cookies.remove('u-nkt');
              }else{
                cookies.remove('dataUser',{domain:".siapptn.com"})
                cookies.remove('idUser',{domain:".siapptn.com"})
                cookies.remove('u-nkt',{domain:".siapptn.com"});
                cookies.remove('dataUser');
                cookies.remove('idUser');
                cookies.remove('u-nkt');
              }
              window.location.reload()
            }
              if(user == undefined){
                return(
                  <div className="container fixed-top bg-white">
                  <header className="blog-header py-3">
                  <div className="row flex-nowrap justify-content-between align-items-center">
                    <div className="col-4 pt-1">
                    <a className="btn btn-sm btn-outline-secondary" href="https://siapptn.com/daftar">Daftar</a>
                    </div>
                    <div className="col-4 text-center">
                      <a className="blog-header-logo text-dark" href="/"><img src="https://siapptn.com/image/siapptn.png" className="logo-img"/></a>
                    </div>
                    <div className="col-4 d-flex justify-content-end align-items-center">
                      <a className="link-secondary" href="#" aria-label="Search">
                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="mx-3" role="img" viewBox="0 0 24 24"><circle cx="10.5" cy="10.5" r="7.5" /><path d="M21 21l-5.2-5.2" /></svg>
                      </a>
                      <a className="btn btn-sm btn-outline-primary" href="https://siapptn.com/masuk">Masuk</a>
                    </div>
                  </div>
                </header>
                </div>
                )
              }else{
                return(
                  <div>
 
                  <Navbar color="white" light expand="md" className="container blog-header fixed-top">
                    <NavbarBrand href="/" className="text-center"><a className="blog-header-logo text-dark" href="/"><img src="https://siapptn.com/image/siapptn.png" className="logo-img"/></a></NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                      <Nav className="ml-auto" navbar>
                        <NavItem className="mt-2">
                          <NavLink href="/components/"> <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="mx-3" role="img" viewBox="0 0 24 24"><circle cx="10.5" cy="10.5" r="7.5" /><path d="M21 21l-5.2-5.2" /></svg></NavLink>
                        </NavItem>
                        
                        <UncontrolledDropdown nav inNavbar>
                          <DropdownToggle nav >
                          <div class="avatar">                   
                              <img class="avatar__image" src={`https://storage.siapptn.com/image/ava/${user[0].image}`} />
                          </div>
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem>
                            <NavLink href={`https://siapptn.com/profile?username=${user[0].username}`}> 
                             Profile
                             </NavLink>
                            </DropdownItem>
                        
                            <DropdownItem divider />
                            <DropdownItem onClick={logOut}>
                              Log out
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Nav>
                    
                    </Collapse>
                  </Navbar>
                  
                </div>
                  
                )
              }
          }
          export default HeaderFitur;