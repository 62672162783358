import React ,{useState, useEffect} from 'react';
import {
  useParams
} from "react-router-dom";
import axios from 'axios';
import {Helmet} from "react-helmet";
import { Markup } from "interweave";
import LoadingPage from './LoadingPage';
function BlogPage(){
  let { id,judul } = useParams();
  const [blogPage,setBlogPage] = useState(null)
  var user = JSON.parse(localStorage.getItem("data"))
  useEffect(()=>{
    getBlogById()
  },[])
  const getBlogById=()=>{
    axios.post(`https://api.meylendra.com:8080/blog/getblogbyid`,{
      id,unique:id
    }).then(res=>{
      setBlogPage(res.data[0])
    })
  }
  const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
  "Juli", "Agustus", "September", "Oktober", "November", "Desember"
];
   if(blogPage){
    return(
      <div className="container mt-5">
         <Helmet>
               <meta charSet="utf-8" />
               <title>{blogPage.judul}</title>
               <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
               <meta property="type" content="article" />
               <meta property="image" content={`https://storage.siapptn.com/image/bukti/to22/${blogPage.thumbnail}`} />
               <meta property="title" content={blogPage.judul} />
               <meta property="description" content={blogPage.caption} />
               <meta property="url" content={"https://blog.siapptn.com"+window.location.pathname} />
               <meta property="site_name" content="blog.siapptn.com" />
               <meta name="description" content={blogPage.caption} />
               <meta name="keywords" content={blogPage.caption} />
               <meta name="googlebot" content="index, follow, follow" />
               <meta name="author" content="Siapptn" />
               <meta name="robots" content="index, follow" />
               <meta name="language" content="id" />
               <meta name="geo.country" content="id" />
               <meta httpEquiv="content-language" content="In-Id" />
               <meta name="geo.placename" content="Indonesia" />
               {/* S:fb meta */}
               <meta property="og:type" content="article" />
               <meta property="og:image" content={`https://storage.siapptn.com/image/bukti/to22/${blogPage.thumbnail}`} />
               <meta property="og:title" content={blogPage.judul} />
               <meta property="og:description" content={blogPage.caption} />
               <meta property="og:url" content={"https://blog.siapptn.com"+window.location.pathname} />
               <meta property="og:site_name" content="blog.siapptn.com" />
               {/* <meta property="fb:app_id" content={324557847592228} /> */}
               {/* e:fb meta */}
               {/* S:tweeter card */}
               <meta name="twitter:card" content="summary_large_image" />
               <meta name="twitter:site" content="@siapptn" />
               <meta name="twitter:creator" content="@siapptn" />
               <meta name="twitter:title" content={blogPage.judul} />
               <meta name="twitter:description" content={blogPage.caption}/>
               <meta name="twitter:image" content={blogPage.thumbnail}/>
               {/* E:tweeter card */}
               <meta name="content_category" content={blogPage.kategori} />
               <link rel="dns-prefetch" href="twitter.com" />
               <link rel="canonical" href={"https://blog.siapptn.com"+window.location.pathname} />
         </Helmet>
       <div className="row g-5">
       <div className="col-md-8">
        <a href={`/kategori/${blogPage.kategori}`} style={{textDecoration:"none"}}> <h3 className="pb-4 mb-4 fst-italic border-bottom mt-5" >
          #{blogPage.kategori}
         </h3></a>
         <article className="blog-post">
           <h2 className="blog-post-title">{blogPage.judul}</h2>
           <p className="blog-post-meta">{monthNames[new Date(blogPage.date_create).getMonth()]} {new Date(blogPage.date_create).getDate()} ,{new Date(blogPage.date_create).getFullYear()} <a href="#">Admin</a></p>
           <div className="text-center">
           <img src={`https://storage.siapptn.com/image/bukti/to22/${blogPage.thumbnail}`} style={{height:"auto",width:"80%"}}/>
           </div>
           <hr/>
           <p>{blogPage.caption}</p>
           <hr />
          
           <Markup content= {blogPage.konten} tagName="span"/>
         </article>
         
       </div>
       <div className="col-md-4 ">
         <div className="position-sticky" style={{top: '6rem'}}>
           <div className="p-4 mb-3 bg-light rounded">
             <h4 className="fst-italic">SIAPPTN</h4>
             <p className="mb-0">Bergabung di SIAPPTN dan jadilah yang paling siap dalam menghadapi tes yang akan datang.</p>
           </div>
           <div className="p-4">
             <h4 className="fst-italic">Category</h4>
             <ol className="list-unstyled mb-0">
               <li><a href="/">All</a></li>
               <li><a href="/kategori/SNBT">SNBT</a></li>
               <li><a href="/kategori/SNBP">SNBP</a></li>
               <li><a href="/kategori/SNMPTN">Ujian mandiri</a></li>
               <li><a href="/kategori/Tips dan trik">Tips dan trik</a></li>
               <li><a href="/kategori/Event">Event</a></li>
               <li><a href="/kategori/PTN">PTN</a></li>
               <li><a href="/kategori/PTS">PTS</a></li>
               <li><a href="/kategori/BIMBEL">BIMBEL</a></li>
               <li><a href="/kategori/Buku">Buku</a></li>
             </ol>
           </div>
           {/* <div className="p-4">
             <h4 className="fst-italic">Elsewhere</h4>
             <ol className="list-unstyled">
               <li><a href="#">GitHub</a></li>
               <li><a href="#">Twitter</a></li>
               <li><a href="#">Facebook</a></li>
             </ol>
           </div> */}
         </div>
       </div>
     </div>
   
      </div>
   )
 
   }else{
    return <LoadingPage/>
   }
}
export default BlogPage;