import React ,{useState, useEffect} from 'react';
import {
  useParams
} from "react-router-dom";
import axios from 'axios';
import { Markup } from "interweave";
import Swal from 'sweetalert2';
function BlogPagePreview(){
  let { id,judul } = useParams();
  const [blogPage,setBlogPage] = useState({})
  
  useEffect(()=>{
    getBlogById()
  },[])
  var user = JSON.parse(localStorage.getItem("data"))
  const getBlogById=()=>{
    axios.post(`https://api.meylendra.com:8080/blog/getblogbyid`,{
      id,unique:id
    }).then(res=>{
      setBlogPage(res.data[0])
    })
  }
  const updatePublish=(status)=>{
    axios.post(`https://api.meylendra.com:8080/blog/updateblog`,{
        id:blogPage.id, judul:blogPage.judul,thumbnail:blogPage.thumbnail,caption:blogPage.caption,kategori:blogPage.kategori,konten:blogPage.konten,id_user:blogPage.id_user,status_blog:status
    }).then((res)=>{
        Swal.fire('success', `Blog berhasil di${status}`, 'success')
    })
  }
  const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
  "Juli", "Agustus", "September", "Oktober", "November", "Desember"
];
const btnPublish=()=>{
    if(user[0].role == "Admin"){
        return (
            <div>
                <button className="btn btn-success" onClick={()=>updatePublish("publish")}>Publish this article</button> {` `}
                <button className="btn btn-danger" onClick={()=>updatePublish("reject")}>Reject this article</button>
            </div>
        )
    }
}
if(user != undefined){
    if(user[0].role == "Admin" || user[0].role == "blogger" ){
        return(
           <div className="container mt-5">
             
            <div className="row g-5">
            <div className="col-md-8">
              <h3 className="pb-4 mb-4 fst-italic border-bottom">
               {blogPage.kategori}
              </h3>
              <article className="blog-post">
                <h2 className="blog-post-title">{blogPage.judul}</h2>
                <p className="blog-post-meta">{monthNames[new Date(blogPage.date_create).getMonth()]} {new Date(blogPage.date_create).getDate()} ,{new Date(blogPage.date_create).getFullYear()} <a href="#">Admin</a></p>
                <div className="text-center">
                <img src={`https://storage.siapptn.com/image/bukti/to22/${blogPage.thumbnail}`} style={{height:"auto",width:"80%"}}/>
                </div>
                <hr/>
                <p>{blogPage.caption}</p>
                <hr />
               
                <Markup content= {blogPage.konten} tagName="span"/>
                <br/>
                {btnPublish()}
               
              </article>
              
            </div>
            <div className="col-md-4 ">
              <div className="position-sticky" style={{top: '6rem'}}>
                <div className="p-4 mb-3 bg-light rounded">
                  <h4 className="fst-italic">SIAPPTN</h4>
                  <p className="mb-0">Bergabung di SIAPPTN dan jadilah yang paling siap dalam menghadapi tes yang akan datang.</p>
                </div>
                <div className="p-4">
                  <h4 className="fst-italic">Category</h4>
                  <ol className="list-unstyled mb-0">
                    <li><a href="#">All</a></li>
                    <li><a href="#">SBMPTN</a></li>
                    <li><a href="#">SNMPTN</a></li>
                    <li><a href="#">Ujian mandiri</a></li>
                    <li><a href="#">Tips dan trik</a></li>
                    <li><a href="#">Event</a></li>
                    <li><a href="#">PTN</a></li>
                    <li><a href="#">PTS</a></li>
                    <li><a href="#">BIMBEL</a></li>
                    <li><a href="#">Buku</a></li>
                  </ol>
                </div>
                <div className="p-4">
                  <h4 className="fst-italic">Elsewhere</h4>
                  <ol className="list-unstyled">
                    <li><a href="#">GitHub</a></li>
                    <li><a href="#">Twitter</a></li>
                    <li><a href="#">Facebook</a></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        
           </div>
        )
    }else{
        return window.location.href ='/'
    }
}else{
    return window.location.href ='/'
}

}
export default BlogPagePreview;