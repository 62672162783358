import React ,{useState, useEffect} from 'react';
import Axios from 'axios';
import Cookies from "universal-cookie";
import Swal from 'sweetalert2'
import { koneksi } from '../helper/environtment';

const cookies = new Cookies();

function BlogListPage(){
    const [page, setPage ] =useState(0)
    const [blogData, setBlogData ] =useState([])
    var user = JSON.parse(localStorage.getItem("data"))
    useEffect(()=>{
       if(user[0].role == "blogger"){
            getBlogById()
       }else{
            getBlogAdmin()
       }
    },[user])
    const getBlogById=()=>{
        Axios.post(`${koneksi}/blog/getallblogbyiduser`,{
            page,id_user:user[0].id
        }).then((res)=>{
            setBlogData(res.data)
        })
    }
    const getBlogAdmin=()=>{
        Axios.post(`${koneksi}/blog/getallblog`,{
            page
        }).then((res)=>{
            setBlogData(res.data)
        })
    }
    const mapData=()=>{
        var data = blogData.map((item,i)=>{
            return(
                <tr>
                                <th scope="row">{i+1}</th>
                                <td>{item.judul}</td>
                                <td>{item.status_blog}</td>
                                <td><a href={`/be28/04/${item.id}`}><button className="btn btn-warning">Edit</button></a>{` `} <a href={`/preview/${item.id}/${item.judul}`}><button className="btn btn-primary">Preview</button></a></td>
                            </tr>
            )
        })
        return data
    }
    const roleTable=()=>{
        return(
            <div className="mt-4">
                <p>Create artikel</p>
                <a href="/be28/03" className="btn btn-success mb-4">create artcle</a>
                <h4>Your article</h4>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">No</th>
                            <th scope="col">Judul</th>
                            <th scope="col">status</th>
                            <th scope="col">action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mapData()}
                    </tbody>
                </table>
            </div>
        )
    }
    if(user != undefined){
    if(user[0].role == "Admin" || user[0].role == "blogger"){
        return(
            <div className="container mt-5">
                   <h1 className="pt-5">Blog management</h1>
                    <div className="p-3 mt-5">
                       
                        <div className="content">
                            <img className="avatar__image float-left" src={`https://storage.siapptn.com/image/ava/${user[0].image}`} />
                            <div className="text">
                                <h3>{user[0].username}</h3>
                                <p>{user[0].nama}</p>
                                <p>Role: {user[0].role}</p>
                            </div>
                        </div>
                        {roleTable()}
                    </div>
            </div>
         )
    }else{
        return window.location.href ='/'
    }
}else{
    return window.location.href ='/'
}
   
}
export default BlogListPage;