import React ,{useState, useEffect} from 'react';
import { Editor, EditorTools,  EditorUtils,
    ProseMirror, } from "@progress/kendo-react-editor";
import Axios from 'axios';
import Cookies from "universal-cookie";
import { CustomInput, Form, FormGroup, Label } from 'reactstrap';
import Swal from 'sweetalert2'
import {
    useParams
  } from "react-router-dom";

const cookies = new Cookies();
const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
} = EditorTools;
function BlogAdminUpdatePage(){
    let { id } = useParams();
    var user = JSON.parse(localStorage.getItem("data"))
    const [thumbnailName, setThumbnailName] = useState("Upload thumbnail blog")
    const editor = React.createRef();
    const textarea = React.createRef();
    const judul = React.useRef("");
    const kategori = React.useRef("");
    const caption = React.useRef("");

    const [blogPage,setBlogPage] = useState({})
    useEffect(()=>{
      getBlogById()
    },[])
    const getBlogById=()=>{
      Axios.post(`https://api.meylendra.com:8080/blog/getblogbyid`,{
        id,unique:id
      }).then(res=>{
          console.log(res.data)
        setBlogPage(res.data[0])
        setThumbnailName(res.data[0].thumbnail)
        setValue(res.data[0].konten)
      })
    }
   const konten =(e)=>{
    setValue(e.value);
   }
   const postKonten = () => {
        const view = editor.current.view;
        var judulBlog = judul.current.value;
        var captionBlog = caption.current.value;
        var kategoriBlog = kategori.current.value
        var konten = EditorUtils.getHtml(view.state)
        var id_user = cookies.get("idUser");

        
        // upload thumnail
        var formData = new FormData()
        var file = document.getElementById('thumbnail');
        if(file.files[0] != undefined){
            var blob = file.files[0].slice(0, file.files[0].size, 'image/png');
            var imageName = 'thumbnail_blog'+'_'+Date.now()+'.png'
            var newFile = new File([blob], `${imageName}`, {type: 'image/png'});
            formData.append('bukti1', newFile,newFile.name)
            var namaThumbnail = newFile.name
            Axios.post(`https://storage.siapptn.com/buktidaftar`,formData)
            .then((res) => {
                Axios.post(`https://api.meylendra.com:8080/blog/updateblog`,{
                    id,judul:judulBlog,thumbnail:namaThumbnail,caption:captionBlog,kategori:kategoriBlog,konten,id_user,status_blog:"on review",unique:id
                }).then((res)=>{
                    Swal.fire('success', 'Blog berhasil terupload dan akan menuju proses review', 'success')
                })
            })
            .catch((err) =>{
            Swal.fire('Oops...', 'Maaf thumbnail gagal terupload harap diulang kembali, pastikan terhubung internet dengan baik.', 'error')
                console.log(err)
            }) 
        }else{
            Axios.post(`https://api.meylendra.com:8080/blog/updateblog`,{
                id, judul:judulBlog,thumbnail:thumbnailName,caption:captionBlog,kategori:kategoriBlog,konten,id_user,status_blog:"on review",unique:id
            }).then((res)=>{
                Swal.fire('success', 'Blog berhasil terupload dan akan menuju proses review', 'success')
            })
        }
        
       
    };
   const [value, setValue] = React.useState(
    EditorUtils.createDocument(
      new ProseMirror.Schema({
        nodes: EditorUtils.nodes,
        marks: EditorUtils.marks,
      }),
      ""
    )
  );
  const onAddFileImageChange = () => {
    if(document.getElementById("thumbnail").files[0] !== undefined) {
        setThumbnailName(document.getElementById("thumbnail").files[0].name)
    }
    else {
        setThumbnailName("Upload thumbnail blog")
    }
}
if(user != undefined){
if(user[0].role == "Admin" || user[0].role == "blogger"){
    return(
       <div className="container mt-5">
       <h1 className="pt-5">Update article</h1>
       <small>Update akan membuat status artikle menjadi on review kembali</small>
       <div className="mb-3 col-8 mt-4">
            <label htmlFor="judul" className="form-label">Kategori artikel</label>
            <select className="form-control form-select form-select-lg mb-4" aria-label="Default select example" ref={kategori} >
                <option selected>Pilih kategori dibawah ini</option>
                <option value={"SBMPTN"} selected={blogPage.kategori == 'SBMPTN'}>SBMPTN</option>
                <option value={"SNMPTN"} selected={blogPage.kategori == 'SNMPTN'}>SNMPTN</option>
                <option value={"SNBT"} selected={blogPage.kategori == 'SNBT'}>SNBT</option>
                <option value={"SNBP"} selected={blogPage.kategori == 'SNBP'}>SNBP</option>
                <option value={"UJIAN MANDIRI"} selected={blogPage.kategori == 'UJIAN MANDIRI'}>UJIAN MANDIRI</option>
                <option value={"Tips dan trik"} selected={blogPage.kategori == 'Tips dan trik'}>Tips dan trik</option>
                <option value={"Event"} selected={blogPage.kategori == 'Event'}>Event</option>
                <option value={"PTN"} selected={blogPage.kategori == 'PTN'}>PTN</option>
                <option value={"PTS"} selected={blogPage.kategori == 'PTS'}>PTS</option>
                <option value={"BIMBEL"} selected={blogPage.kategori == 'BIMBEL'}>BIMBEL</option>
                <option value={"Buku"} selected={blogPage.kategori == 'Buku'}>Buku</option>
                <option value={"Lainnya"} selected={blogPage.kategori == 'Lainnya'}>Lainnya</option>
            </select>
            </div>
           
            <div className="mb-3 col-8">
                <label htmlFor="judul" className="form-label">Judul</label>
                <input type="email" className="form-control" id="judul" aria-describedby="judul" placeholder="judul artikel" ref={judul} defaultValue={blogPage.judul}/>
            </div>
            <div className="mb-3 col-8">
                <label htmlFor="judul" className="form-label">Caption</label>
                <textarea type="text" className="form-control" id="caption" aria-describedby="caption" placeholder="Caption artikel" ref={caption} defaultValue={blogPage.caption}/>
            </div>
            <div className="mb-3 col-8">
                <label htmlFor="judul" className="form-label">Thumbnail</label>
                <CustomInput type="file" id="thumbnail" name="customFile" label={thumbnailName} onChange={onAddFileImageChange} />
                <small>Thumbnail akan muncul pada halaman beranda dan merupakan gambar yang muncul pertama pada blog</small>
            </div>
            <div className="mb-3 col-12">
                <label htmlFor="konten" className="form-label">Konten</label>
                <Editor
                value={value}
                tools={[
                    [Bold, Italic, Underline, Strikethrough],
                    [Subscript, Superscript],
                    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                    [Indent, Outdent],
                    [OrderedList, UnorderedList],
                    FontSize,
                    FontName,
                    FormatBlock,
                    [Undo, Redo],
                    [Link, Unlink, InsertImage, ViewHtml],
                    [InsertTable],
                    [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                    [DeleteRow, DeleteColumn, DeleteTable],
                    [MergeCells, SplitCell],
                ]}
                contentStyle={{
                    height: 630,
                }}
                onChange={konten}
                ref={editor}
                />
            </div>
            <div className="mb-3 col-8">
                <button className="k-button k-button-icontext btn" onClick={postKonten}>
                    Post to review
                </button>
            </div>
                <br />
    
       </div>
    )
            }else{
                return window.location.href ='/'
            }
        }else{
            return window.location.href ='/'
        }
}
export default BlogAdminUpdatePage;