import React ,{useState, useEffect} from 'react';
import CarousellFitur from './CarousellFitur';
import { Markup } from "interweave";
import {Helmet} from "react-helmet";
import Axios from 'axios';
import {
  useParams
} from "react-router-dom";
function Homes(){
    let { id } = useParams();
    const [page, setPage ] =useState(0)
    const [dataBlog, setDataBlog ] =useState([])
   useEffect(()=>{
    if(id!= undefined){
      getAllDataPerPageCategory()
    }else{
      getAllDataPerPage()
    }
   },[id])
   const getAllDataPerPage=()=>{
     Axios.post(`https://api.meylendra.com:8080/blog/getallblogpublish`,{
       page,unique:page
     }).then((res)=>{
      setDataBlog(res.data)
     })
   }
   const getAllDataPerPageCategory=()=>{
    Axios.post(`https://api.meylendra.com:8080/blog/getallblogbycategory`,{
      page,kategori:id,unique:page
    }).then((res)=>{
     setDataBlog(res.data)
    })
  }
   const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
  ];
   const mapBlog=()=>{
     if(dataBlog.length >0){
      var data = dataBlog.map((item)=>{
        return(
         <div className="col-md-6">
         <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
           <div className="col p-4 d-flex flex-column position-static">
             <strong className="d-inline-block mb-2 text-success">{item.kategori}</strong>
             <h3 className="mb-0">{item.judul}</h3>
             <div className="mb-1 text-muted">{monthNames[new Date(item.date_create).getMonth()]} {new Date(item.date_create).getDate()} ,{new Date(item.date_create).getFullYear()}</div>
             <p className="card-text mb-auto caption-text"><Markup content={item.caption} tagName="span"/></p>
             <a href={`/page/${item.id}/${item.judul}`} className="stretched-link">Continue reading</a>
           </div>
           <div className="col-auto d-none d-lg-block">
             <img src={`https://storage.siapptn.com/image/bukti/to22/${item.thumbnail}`} className="thumbnail-img"/>
           </div>
         </div>
       </div>
        )
      })
      return data;
     }else{
      return(
        <div className="text-center">
           <h3>Belum ada artikel pada kategori ini</h3>
        </div>
      )
     }
     
    
   }
   
    return(
        <div >
           <Helmet>
                <meta charSet="utf-8" />
                <title>Siapptn Blog</title>
                <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
                <meta property="type" content="article" />
                <meta property="image" content={'https://siapptn.com/favicon.png'} />
                <meta property="og:image" content="https://siapptn.com/favicon.png" />
                <meta property="title" content={'Siapptn Blog'} />
                <meta property="description" content={'Dapatkan informasi mengenai tryout, UTBK SNBT, SNBP, Ujian mandiri ptn dan ujian kedinasan disini'} />
                <meta property="url" content={"https://blog.siapptn.com"+window.location.pathname} />
                <meta property="site_name" content="blog.siapptn.com" />
                <meta name="description" content={'Dapatkan informasi mengenai tryout, UTBK, Ujian mandiri ptn dan ujian kedinasan disini'} />
                <meta name="keywords" content={'Dapatkan informasi mengenai tryout, UTBK, Ujian mandiri ptn dan ujian kedinasan disini'} />
                <meta name="googlebot" content="index, follow, follow" />
                <meta name="author" content="Siapptn" />
                <meta name="robots" content="index, follow" />
                <meta name="language" content="id" />
                <meta name="geo.country" content="id" />
                <meta httpEquiv="content-language" content="In-Id" />
                <meta name="geo.placename" content="Indonesia" />
                {/* S:fb meta */}
                <meta property="og:type" content="article" />
                <meta property="og:title" content={'Siapptn Blog'} />
                <meta property="og:description" content={'Dapatkan informasi mengenai tryout, UTBK, Ujian mandiri ptn dan ujian kedinasan disini'} />
                <meta property="og:url" content={"https://blog.siapptn.com"+window.location.pathname} />
                <meta property="og:site_name" content="blog.siapptn.com" />
                {/* <meta property="fb:app_id" content={324557847592228} /> */}
                {/* e:fb meta */}
                {/* S:tweeter card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@siapptn" />
                <meta name="twitter:creator" content="@siapptn" />
                <meta name="twitter:title" content={'Siapptn Blog'} />
                <meta name="twitter:description" content={'Dapatkan informasi mengenai tryout, UTBK, Ujian mandiri ptn dan ujian kedinasan disini'}/>
                <meta name="twitter:image" content={'Siapptn Blog'}/>
                {/* E:tweeter card */}
                <link rel="dns-prefetch" href="twitter.com" />
                <link rel="canonical" href={"https://blog.siapptn.com"+window.location.pathname} />
          </Helmet>
        <div className="container">
          
          <div className="nav-scroller py-1 mb-2" style={{top: '5rem'}}>
            <nav className="nav d-flex justify-content-between">
              <a className="p-2 link-secondary" href="/">Beranda</a>
              <a href="/kategori/SNBT" className="p-2 link-secondary">SNBT</a>
              <a href="/kategori/SNBP" className="p-2 link-secondary">SNBP</a>
              <a href="/kategori/SNMPTN" className="p-2 link-secondary">Ujian mandiri</a>
              <a href="/kategori/Tips dan trik" className="p-2 link-secondary">Tips dan trik</a>
              <a href="/kategori/Event" className="p-2 link-secondary">Event</a>
              <a href="/kategori/PTN" className="p-2 link-secondary">PTN</a>
              <a href="/kategori/PTS" className="p-2 link-secondary">PTS</a>
              <a href="/kategori/BIMBEL" className="p-2 link-secondary">BIMBEL</a>
              <a href="/kategori/Buku" className="p-2 link-secondary">Buku</a>
            </nav>
          </div>
        </div>
        <main className="container">
          <div className="p-4 p-md-5 mb-4 text-white rounded text-center" style={{marginTop: '4rem'}}>
              <CarousellFitur/>
          </div>
          
          <div className="row mb-2">
            {mapBlog()}
          
          </div>
       
        </main>
        <div className="container">
        <footer className="py-5 border-top">
          <div className="row">
            <div className="col-4">
              <h5>Siapptn</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><a href="https://siapptn.com/sbmptn" className="nav-link p-0 text-muted">Siap Sbmptn</a></li>
                <li className="nav-item mb-2"><a href="https://siapptn.com/siap-um" className="nav-link p-0 text-muted">Siap Um</a></li>
                <li className="nav-item mb-2"><a href="https://siapptn.com/siap-bimbel" className="nav-link p-0 text-muted">Siap Bimbel</a></li>
                <li className="nav-item mb-2"><a href="https://siapptn.com/siappoin" className="nav-link p-0 text-muted">Siappoin</a></li>
                <li className="nav-item mb-2"><a href="https://siapptn.com/sbmptn" className="nav-link p-0 text-muted">Siap Event</a></li>
              </ul>
            </div>
            <div className="col-4">
              <h5>Lainnya</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><a href="https://siapptn.com/syarat-dan-ketentuan" className="nav-link p-0 text-muted">Syarat dan Ketentuan</a></li>
                <li className="nav-item mb-2"><a href="https://siapptn.com/kebijakan-privasi" className="nav-link p-0 text-muted">Kebijakan privasi</a></li>
                <li className="nav-item mb-2"><a href="https://siapptn.com/kebijakan-pengembalian" className="nav-link p-0 text-muted">Kebijakan pengembalian</a></li>
              </ul>
            </div>
            <div className="col-md-3  mt-3 mt-md-0">
                  <div className='footer-title-homepage clickable-homepage'>Kontak</div>
                  <div className='footer-content-homepage row mt-2 mt-md-3'>
                  <i class="fa fa-envelope col-1"></i>
                      <span className='col-10 clickable-homepage'>info@siapptn.com</span>
                  </div>
                  <div className='footer-content-homepage row mt-2 mt-md-3'> 
                      <i className='far fa-map col-1'></i> 
                      <span className='col-10  clickable-homepage'>Jl Jati 3 No 2 Cluster Jati Taman Royal 3 Tangerang</span> 
                  </div>
              </div>
            
          </div>
          <div className="d-flex justify-content-between py-4 my-4 border-top">
            <p>© 2021 Siapptn, Inc. All rights reserved.</p>
            <ul className="list-unstyled d-flex">
              <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width={24} height={24}><use xlinkHref="#twitter" /></svg></a></li>
              <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width={24} height={24}><use xlinkHref="#instagram" /></svg></a></li>
              <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width={24} height={24}><use xlinkHref="#facebook" /></svg></a></li>
            </ul>
          </div>
        </footer>
      </div>
      </div>
    )
}
export default Homes;