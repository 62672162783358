import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Homes from './Component/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import HeaderFitur from './Component/HeaderFitur';
import BlogPage from './Component/BlogPage';
import AdminPage from './Component/AdminPage';
import BlogListPage from './Component/BlogListPage';
import { useEffect } from 'react';
import Cookies from "universal-cookie";
import { keepLogin } from './helper/function';
import BlogAdminUpdatePage from './Component/BlogAdminUpdate';
import BlogPagePreview from './Component/BlogPagePreview';

const cookies = new Cookies();
function App() {
  useEffect(()=>{
    const cookienya = cookies.get("dataUser");
    const id_cookie= cookies.get("idUser");
    const token_id= cookies.get("u-nkt");
    if(cookienya !== undefined&&id_cookie!== undefined){
      console.log("masuk")
      keepLogin(cookienya,token_id,id_cookie);
      if(cookienya == '' || id_cookie == ''){
        localStorage.removeItem("user")
        cookies.remove('dataUser');
        cookies.remove('idUser');
      }
    }else{
      localStorage.removeItem("user")
      cookies.remove('dataUser');
      cookies.remove('idUser');
    }
  },[cookies])
  return (
    <div className="App">
    <HeaderFitur/>
    <Router>
        <Switch>
          <Route path="/be28/02">
            <BlogListPage/>
          </Route>
          <Route path="/be28/03">
            <AdminPage/>
          </Route>
          <Route path="/be28/04/:id">
            <BlogAdminUpdatePage/>
          </Route>
          <Route path="/page/:id/:judul">
            <BlogPage/>
          </Route>
          <Route path="/preview/:id/:judul">
            <BlogPagePreview/>
          </Route>
          <Route path="/kategori/:id">
            <Homes/>
          </Route>
          <Route path="/">
            <Homes/>
          </Route>
        </Switch>
    </Router>
     
    </div>
  );
}

export default App;
