import React ,{useState, useEffect} from 'react';
import { Editor, EditorTools,  EditorUtils,
    ProseMirror, } from "@progress/kendo-react-editor";
import Axios from 'axios';
import Cookies from "universal-cookie";
import { CustomInput, Form, FormGroup, Label } from 'reactstrap';
import Swal from 'sweetalert2'

const cookies = new Cookies();
const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
} = EditorTools;
function AdminPage(){
    const [thumbnailName, setThumbnailName] = useState("Upload thumbnail blog")
    const editor = React.createRef();
    const textarea = React.createRef();
    const judul = React.useRef("");
    const kategori = React.useRef("");
    const caption = React.useRef("");
    var user = JSON.parse(localStorage.getItem("data"))
   const konten =(e)=>{
    setValue(e.value);
   }
   const postKonten = () => {
        const view = editor.current.view;
        var judulBlog = judul.current.value;
        var captionBlog = caption.current.value;
        var kategoriBlog = kategori.current.value
        var konten = EditorUtils.getHtml(view.state)
        var id_user = cookies.get("idUser");


        // upload thumnail
        var formData = new FormData()
        var file = document.getElementById('thumbnail');
        var blob = file.files[0].slice(0, file.files[0].size, 'image/png');
        var imageName = 'thumbnail_blog'+'_'+Date.now()+'.png'
        var newFile = new File([blob], `${imageName}`, {type: 'image/png'});
        formData.append('bukti1', newFile,newFile.name)
        var namaThumbnail = newFile.name
        Axios.post(`https://storage.siapptn.com/buktidaftar`,formData)
        .then((res) => {
            Axios.post(`https://api.meylendra.com:8080/blog/postblog`,{
                judul:judulBlog,thumbnail:namaThumbnail,caption:captionBlog,kategori:kategoriBlog,konten,id_user,status_blog:"on review"
            }).then((res)=>{
                Swal.fire('success', 'Blog berhasil terupload dan akan menuju proses review', 'success')
            })
        })
        .catch((err) =>{
        Swal.fire('Oops...', 'Maaf thumbnail gagal terupload harap diulang kembali, pastikan terhubung internet dengan baik.', 'error')
            console.log(err)
        }) 
       
         };
   const [value, setValue] = React.useState(
    EditorUtils.createDocument(
      new ProseMirror.Schema({
        nodes: EditorUtils.nodes,
        marks: EditorUtils.marks,
      }),
      ""
    )
  );
  const onAddFileImageChange = () => {
    if(document.getElementById("thumbnail").files[0] !== undefined) {
        setThumbnailName(document.getElementById("thumbnail").files[0].name)
    }
    else {
        setThumbnailName("Upload thumbnail blog")
    }
}
if(user != undefined){
    if(user[0].role == "Admin" || user[0].role == "blogger"){
    return(
       <div className="container mt-5">
       <h1 className="pt-5">Create article</h1>
       <div className="mb-3 col-8">
            <label htmlFor="judul" className="form-label">Kategori artikel</label>
            <select className="form-control form-select form-select-lg mb-4" aria-label="Default select example" ref={kategori} >
                <option selected>Pilih kategori dibawah ini</option>
                <option value={"SNBT"}>SNBT</option>
                <option value={"SNBP"}>SNBP</option>
                <option value={"UJIAN MANDIRI"}>UJIAN MANDIRI</option>
                <option value={"Tips dan trik"}>Tips dan trik</option>
                <option value={"Event"}>Event</option>
                <option value={"PTN"}>PTN</option>
                <option value={"PTS"}>PTS</option>
                <option value={"BIMBEL"}>BIMBEL</option>
                <option value={"Buku"}>Buku</option>
                <option value={"Lainnya"}>Lainnya</option>
            </select>
            </div>
           
            <div className="mb-3 col-8">
                <label htmlFor="judul" className="form-label">Judul</label>
                <input type="email" className="form-control" id="judul" aria-describedby="judul" placeholder="judul artikel" ref={judul}/>
            </div>
            <div className="mb-3 col-8">
                <label htmlFor="judul" className="form-label">Caption</label>
                <textarea type="text" className="form-control" id="caption" aria-describedby="caption" placeholder="Caption artikel" ref={caption}/>
            </div>
            <div className="mb-3 col-8">
                <label htmlFor="judul" className="form-label">Thumbnail</label>
                <CustomInput type="file" id="thumbnail" name="customFile" label={thumbnailName} onChange={onAddFileImageChange} />
                <small>Thumbnail akan muncul pada halaman beranda dan merupakan gambar yang muncul pertama pada blog</small>
            </div>
            <div className="mb-3 col-12">
                <label htmlFor="konten" className="form-label">Konten</label>
                <Editor
                value={value}
                tools={[
                    [Bold, Italic, Underline, Strikethrough],
                    [Subscript, Superscript],
                    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                    [Indent, Outdent],
                    [OrderedList, UnorderedList],
                    FontSize,
                    FontName,
                    FormatBlock,
                    [Undo, Redo],
                    [Link, Unlink, InsertImage, ViewHtml],
                    [InsertTable],
                    [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                    [DeleteRow, DeleteColumn, DeleteTable],
                    [MergeCells, SplitCell],
                ]}
                contentStyle={{
                    height: 630,
                }}
                defaultContent={"sas"}
                onChange={konten}
                ref={editor}
                />
            </div>
            <div className="mb-3 col-8">
                <button className="k-button k-button-icontext btn" onClick={postKonten}>
                    Post to review
                </button>
            </div>
                <br />
    
       </div>
    )
}else{
    return window.location.href ='/'
}
}else{
return window.location.href ='/'
}
}
export default AdminPage;